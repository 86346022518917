// AuthContext.js
import React, { createContext, useState, useContext } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    // Check for user data in localStorage or sessionStorage
    const userData = localStorage.getItem('userData');
    return !!userData;
  });

  const login = () => setIsAuthenticated(true);
  const logout = () => {
    // localStorage.removeItem('userData');
    localStorage.removeItem('userData');
    localStorage.removeItem('password')
    localStorage.removeItem('username')
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
