import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import "../index.css";
import Table from "react-bootstrap/Table";
import axios from "axios";
import Swal from "sweetalert2";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@mui/material"; // Import Button components
import * as XLSX from "xlsx"; // Import xlsx library
import { saveAs } from "file-saver"; // For saving files

const AllUrl = () => {
  const [permanentUrls, setPermanentUrls] = useState([]);
  const [expirableUrls, setExpirableUrls] = useState([]);
  const [activeTab, setActiveTab] = useState("permanent");

  // Pagination state
  const [page, setPage] = useState(1);  
  const rowsPerPage = 8; // Fixed to show 8 records per page

  // Search state
  const [searchQuery, setSearchQuery] = useState("");

  // Function to fetch URL data from the backend
  const fetchData = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "alldata"
      );     
      const  all  = response.data.all;
      // Separate URLs based on isExpirable flag
      const permanent = all.filter((url) => url.isExpirable === false);     
      const expirable = all.filter((url) => url.isExpirable === true);      
      setPermanentUrls(permanent);
      setExpirableUrls(expirable);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  // Handle search input
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  // Function to handle URL deletion
  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        // Show loading state
        Swal.fire({
          title: "Deleting...",
          didOpen: () => {
            Swal.showLoading();
          },
        });

        // Await the delete request
        await axios.delete(`${process.env.REACT_APP_API_URL}deleteOne/${id}`);

        // Refresh data
        await fetchData();

        // Show success message
        Swal.fire("Deleted!", "Your URL has been deleted.", "success");
      }
    } catch (error) {
      console.error("Error deleting URL:", error);
      Swal.fire("Error!", "There was a problem deleting the URL.", "error");
    }
  };

  // Copy to clipboard function
  const copyToClipboard = (text) => {
    const input = document.createElement("input");
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
  };

  // Function to handle copying the short URL to clipboard
  const handleCopy = (shortUrl) => {
    if (shortUrl) {
      copyToClipboard(shortUrl);
      toast.success("Short URL copied to clipboard!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        style: { fontSize: "12px" }, // Small font size
      });
    }
  };

  // Handle page change (Next and Previous)
  const handleNextPage = () => {
    if (page < maxPage) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  // Filter data based on search query
  const filteredPermanentUrls = permanentUrls.filter(
    (url) =>
      url.originalUrl.toLowerCase().includes(searchQuery.toLowerCase()) ||
      url.fullShortUrl.toLowerCase().includes(searchQuery.toLowerCase()) ||
      url.createdAt.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const filteredExpirableUrls = expirableUrls.filter(
    (url) =>
      url.originalUrl.toLowerCase().includes(searchQuery.toLowerCase()) ||
      url.fullShortUrl.toLowerCase().includes(searchQuery.toLowerCase()) ||
      url.createdAt.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    fetchData();
  }, []);

  // Calculate max number of pages
  const maxPage = Math.ceil(filteredPermanentUrls.length / rowsPerPage);

  // Slice the data to show only the current page records
  const permanentUrlsToShow = filteredPermanentUrls.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );
  const expirableUrlsToShow = filteredExpirableUrls.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  // Export to Excel function using xlsx
  const exportToExcel = () => {
    let dataToExport = [];
    let sheetName = "";

    // Export based on active tab
    if (activeTab === "permanent") {
      dataToExport = filteredPermanentUrls;
      sheetName = "Permanent URLs";
    } else if (activeTab === "expirable") {
      dataToExport = filteredExpirableUrls;
      sheetName = "Expirable URLs";
    }

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Create sheet from the selected data
    const sheet = XLSX.utils.json_to_sheet(dataToExport);

    // Append sheet to the workbook
    XLSX.utils.book_append_sheet(wb, sheet, sheetName);

    // Write the Excel file and trigger download
    const excelFile = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelFile], { type: "application/octet-stream" });
    saveAs(blob, `${sheetName}_URL_List.xlsx`);
  };

  return (
    <div>
      <Navbar />
      <div className="container-fluid px-4">
        {/* Search Input */}
        <div className="d-flex justify-content-between mb-3">
          <div>
            <button
              className={`btn ${
                activeTab === "permanent" ? "btn-primary" : "btn-secondary"
              }`}
              onClick={() => setActiveTab("permanent")}
            >
              Permanent URL Records
            </button>
            <button
              className={`btn ${
                activeTab === "expirable" ? "btn-primary" : "btn-secondary"
              } mx-2`}
              onClick={() => setActiveTab("expirable")}
            >
              Expirable URL Records
            </button>
            <button className="btn btn-success" onClick={exportToExcel}>
              Export to Excel
            </button>
          </div>
          <div>
            <input
              type="text"
              className="form-control"
              placeholder="Search URL..."
              value={searchQuery}
              onChange={handleSearch}
            />
          </div>
        </div>

        {/* Render Table based on Active Tab */}
        {activeTab === "permanent" && (
          <>
            <h5>Permanent URL Records</h5>
            {filteredPermanentUrls.length > 0 ? (
              <Table striped bordered hover responsive>
                <thead className="text-center">
                  <tr>
                    <th>No.</th>
                    <th>Original URL</th>
                    <th>Entry Date</th>
                    <th>Short URL</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {permanentUrlsToShow.map((url, index) => (
                    <tr key={url._id}>
                      <td>{(page - 1) * rowsPerPage + index + 1}</td>
                      <td>
                        <div className="cell-wrapper">{url.originalUrl}</div>
                      </td>
                      <td>
                        <div className="cell-wrapper">
                          {new Date(url.createdAt).toLocaleString("en-IN", {
                            timeZone: "Asia/Kolkata",
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                            hour12: true,
                          })}
                        </div>
                      </td>
                      <td className="cell-wrapper-new">
                        <div className="cell-wrapper">
                          {url.result ||
                            process.env.REACT_APP_API_URL +
                              `${url.schemeType}/${url.fullShortUrl}`}
                        </div>
                      </td>
                      <td>
                        <button
                          className="btn btn-primary mx-2"
                          onClick={() =>
                            handleCopy(
                              url.result ||
                                process.env.REACT_APP_API_URL +
                                  `${url.schemeType}/${url.fullShortUrl}`
                            )
                          }
                        >
                          Copy
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <Table striped bordered hover responsive="sm">
                <tbody>
                  <tr>
                    <td colSpan="4" className="text-center">
                      No permanent URLs found.
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}

            {/* Pagination Buttons */}
            <div className="d-flex" style={{float:'right'}}>
              <Button
                variant="outlined"
                onClick={handlePrevPage}
                disabled={page === 1}
                className="text-white border"
              >
                Previous
              </Button>
              <Button
                variant="outlined"
                onClick={handleNextPage}
                disabled={page === maxPage}
                sx={{ marginLeft: "10px" }}
                className="text-white border"
              >
                Next
              </Button>
            </div>
          </>
        )}

        {activeTab === "expirable" && (
          <>
            <h5>
              Expirable URL Records{" "}
              <i style={{ fontSize: "14px" }}>
                (URLs are valid for only 10 days)
              </i>
            </h5>
            {filteredExpirableUrls.length > 0 ? (
              <Table striped bordered hover responsive="lg">
                <thead className="text-center">
                  <tr>
                    <th>No.</th>
                    <th>Original URL</th>
                    <th>Entry Date</th>
                    <th>Short URL</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {expirableUrlsToShow.map((url, index) => (
                    <tr key={url._id}>
                      <td>{(page - 1) * rowsPerPage + index + 1}</td>
                      <td>
                        <div className="cell-wrapper">{url.originalUrl}</div>
                      </td>
                      <td>
                        <div className="cell-wrapper">
                          {new Date(url.createdAt).toLocaleString("en-IN", {
                            timeZone: "Asia/Kolkata",
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                            hour12: true,
                          })}
                        </div>
                      </td>
                      <td className="cell-wrapper-new">
                        <div className="cell-wrapper">
                          {url.result ||
                            process.env.REACT_APP_API_URL +
                              `${url.schemeType}/${url.fullShortUrl}`}
                        </div>
                      </td>
                      <td>
                        <button
                          className="btn btn-danger"
                          onClick={() => handleDelete(url._id)}
                        >
                          Delete
                        </button>
                        <button
                          className="btn btn-primary mx-2"
                          onClick={() =>
                            handleCopy(
                              url.result ||
                                process.env.REACT_APP_API_URL +
                                  `${url.schemeType}/${url.fullShortUrl}`
                            )
                          }
                        >
                          Copy
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <Table striped bordered hover responsive="sm">
                <tbody>
                  <tr>
                    <td colSpan="4" className="text-center">
                      No expirable URLs found.
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}

            {/* Pagination Buttons */}
            <div className="d-flex" style={{float:'right'}}>
              <Button
                variant="outlined"
                onClick={handlePrevPage}
                disabled={page === 1}
                className="text-white border"
              >
                Previous
              </Button>
              <Button
                variant="outlined"
                onClick={handleNextPage}
                disabled={page === maxPage}
                sx={{ marginLeft: "10px" }}
                className="text-white border"
              >
                Next
              </Button>
            </div>
          </>
        )}
      </div>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ fontSize: "12px" }} // Ensuring all toast messages are small
      />
    </div>
  );
};

export default AllUrl;
