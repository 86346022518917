import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "./AuthContext"; // Import the useAuth hook
import { toast, ToastContainer } from "react-toastify";

const Navbar = () => {
  const { isAuthenticated, logout } = useAuth(); // Use the authentication context
  const navigate = useNavigate();
  const handleLogout = async () => {
    const success = await logout();

    if (!success) {
      toast.success("Logout Success.. 😊", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        style: { fontSize: "12px" },
      });
    }
    navigate("/"); // Redirect to the home page
  };

  return (
    <div>
      <div
        className="container-fluid p-0"
        style={{ backgroundColor: "lightslategray" }}
      >
        <ul
          style={{
            listStyle: "none",
            display: "flex",
            gap: "20px",
            padding: "15px 30px",
            fontWeight: "600",
          }}
        >
          {isAuthenticated ? (
            <li>
              <NavLink
                to="/allurl"
                style={({ isActive }) => ({
                  color: isActive ? "red" : "white",
                })}
              >
                ALL URL
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {isAuthenticated ? (
            <li>
              <NavLink
                to="/short-url"
                style={({ isActive }) => ({
                  color: isActive ? "red" : "white",
                })}
              >
                SHORT URL
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {isAuthenticated ? (
            <li>
              <NavLink
                to="/add-scheme"
                style={({ isActive }) => ({
                  color: isActive ? "red" : "white",
                })}
              >
                ADD SCHEME
              </NavLink>
            </li>
          ) : (
            ""
          )}

          {isAuthenticated ? (
            <li>
              <button
                onClick={handleLogout}
                style={{
                  background: "transparent",
                  border: "none",
                  color: "white",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                LOGOUT
              </button>
            </li>
          ) : (
            <li>
              <NavLink
                to="/"
                style={({ isActive }) => ({
                  color: isActive ? "red" : "white",
                })}
              >
                LOGIN
              </NavLink>
            </li>
          )}
        </ul>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ fontSize: "12px" }} // Ensuring all toast messages are small
      />
    </div>
  );
};

export default Navbar;
