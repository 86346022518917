import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Swal from "sweetalert2";
import axios from "axios";
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import CryptoJS from "crypto-js";

const LoginPage = () => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [errors, setErrors] = useState({});
  const { isAuthenticated, login } = useAuth();
  const navigate = useNavigate();
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/allurl");
    }

    // Check localStorage for saved credentials
    const encryptedUsername = localStorage.getItem("username");
    const encryptedPassword = localStorage.getItem("password");

    if (encryptedUsername && encryptedPassword) {
      const decryptedUsername = CryptoJS.AES.decrypt(
        encryptedUsername,
        secretKey
      ).toString(CryptoJS.enc.Utf8);
      const decryptedPassword = CryptoJS.AES.decrypt(
        encryptedPassword,
        secretKey
      ).toString(CryptoJS.enc.Utf8);
      setFormData({ username: decryptedUsername, password: decryptedPassword });
      setRememberMe(true);
    }
  }, [isAuthenticated, secretKey, navigate]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let errors = {};
    const { username, password } = formData;

    if (!username) {
      errors.username = "Username is required.";
    }

    if (!password) {
      errors.password = "Password is required.";
    } else if (password.length < 6) {
      errors.password = "Password must be at least 6 characters.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleCheckboxChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    try {
      const response = await axios.post(process.env.REACT_APP_API_URL+
        "admin/login",
        formData
      );

      if (response.status === 200) {
        login(); // Set user as logged in

        if (!rememberMe || rememberMe) {
          const encryptedUsername = CryptoJS.AES.encrypt(
            formData.username,
            secretKey
          ).toString();
          const encryptedPassword = CryptoJS.AES.encrypt(
            formData.password,
            secretKey
          ).toString();
          localStorage.setItem("username", encryptedUsername);
          localStorage.setItem("password", encryptedPassword);
          localStorage.setItem("userData", JSON.stringify(response.data));  
        }
        Swal.fire({
          icon: "success",
          title: "Login success",
          text: "Logged in successfully",
        });
        navigate("/allurl");
      } else {
        throw new Error("Login failed");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Invalid username or password",
      });
      console.error("Login error", error);
    }
  };

  return (
    <>
      <Navbar />
      <Container>
        <Row className="justify-content-center mt-5">
          <Col md={6} lg={5}>
            <div className="card p-4 border-0">
              <h3 className="text-center mb-4">Admin Login</h3>
              <Form onSubmit={handleLogin}>
                <Form.Group className="mb-3" controlId="formUsername">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your username"
                    name="username"
                    onChange={handleChange}
                    value={formData.username}
                  />
                  {errors.username && (
                    <p style={{ color: "red" }}>{errors.username}</p>
                  )}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter your password"
                    name="password"
                    onChange={handleChange}
                    value={formData.password}
                  />
                  {errors.password && (
                    <p style={{ color: "red" }}>{errors.password}</p>
                  )}
                </Form.Group>

                <Form.Group className="mb-3 d-flex justify-content-between align-items-center">
                  <Form.Check
                    type="checkbox"
                    id="rememberMe"
                    label="Remember me"
                    checked={rememberMe}
                    onChange={handleCheckboxChange}
                  />
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100">
                  Login
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LoginPage;
