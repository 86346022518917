// App.js
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import FrontPage from './component/FrontPage';
import AllUrl from './component/AllUrl';
import ErrorPage from './component/ErrorPage';
import LoginPage from './component/LoginPage';
import { useAuth } from './component/AuthContext'; 
import AddScheme from './component/AddScheme';

const ProtectedRoute = ({ element, ...rest }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? element : <Navigate to="/" />;
};

function App() {
  return (
    <Routes>
      <Route path='/short-url' element={<ProtectedRoute element={<FrontPage />} />} />
      <Route path='/allurl' element={<ProtectedRoute element={<AllUrl />} />} />
      <Route path='/add-scheme' element={<ProtectedRoute element={<AddScheme />} />} />
      <Route path='/' element={<LoginPage />} />
      <Route path='*' element={<ErrorPage message="Page not found." />} />
    </Routes>
  );
}

export default App;
