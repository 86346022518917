import React, { useState } from 'react';
import Navbar from './Navbar';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { toast } from 'react-toastify';

const AddScheme = () => {   
  const [formdata, setformdata] = useState({
    schemeType: ''
  });

  const handlechange = (e) => {
    setformdata({ ...formdata, [e.target.name]: e.target.value });
  };    

  const handlesubmit = async () => {
    if (!formdata.schemeType) {
      toast.error('Scheme name is required!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
      return;
    }

    try {
      const SetData = await axios.post(`${process.env.REACT_APP_API_URL}scheme/add`, formdata);
      console.log("Data", SetData);
      toast.success('Scheme added successfully!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
      // Clear the input after successful submission
      setformdata({ schemeType: '' });
    } catch (error) {
      console.log("Error", error);
      toast.error('Failed to add scheme. Please try again.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }
  };

  return (
    <>
      <Navbar />
      <div className="container   p-5">   
        <div className="row " style={{padding:'0px 20% '}}>
        <div className="card card-style p-5" >
          <Form.Label htmlFor="inputSchemeType">Scheme Name</Form.Label>
          <Form.Control
            type="text" 
            name='schemeType'
            value={formdata.schemeType}
            onChange={handlechange}
            required
            className='input-style'
          />          
          <div className="form-group text-center py-3">
            <button className="btn btn-primary" onClick={handlesubmit}>Add Scheme</button>
          </div>
        </div> 
        </div>
       
       
      </div>
    </>
  );
};

export default AddScheme;
