import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

const ErrorPage = ({ message }) => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/'); // Navigate to home or any other page
  };

  return (
    <div className="container-fluid mt-5 d-flex justify-content-center">
      <div className="card p-4 shadow-sm rounded" style={{ width: "100%", maxWidth: "600px" }}>
        <h4 className="text-center text-white mb-4">Error</h4>
        <p className="text-center text-white">{message || 'An unexpected error occurred.'}</p>
        <div className="d-flex justify-content-center mt-3">
          <Button variant="primary" onClick={handleGoHome}>
            Go to Home
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
