import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";
import Navbar from "./Navbar";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Select from "react-select"; // Import react-select
import { toast } from "react-toastify";

const FrontPage = () => {
  const [formData, setFormData] = useState({
    originalUrl: "",
    fullShortUrl: "",
    category: "non-expirable",
    schemeType: "", // New field for scheme category
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  
  const [optionItem, setOptionItem] = useState([]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCategoryChange = (selectedOption) => {
    setFormData({ ...formData, schemeType: selectedOption ? selectedOption.label : "" });
  };

  const FetchAllScheme = async () => {
    try {
      const AllScheme = await axios.get(process.env.REACT_APP_API_URL + "all-schemes");
      const Inshort = await AllScheme.data.result;
      // Format options for react-select
      const formattedOptions = Inshort.map(scheme => ({  
        label: scheme.schemeType,
        value: scheme.schemeType 
      }));
      
      setOptionItem(formattedOptions);
    } catch (error) {
      console.log("error", error);
    }
  };

  const fillData = async () => {
    setError(""); 
    setLoading(true);
  
    try { 
      // Setting the `isExpirable` flag based on the selected category
      const isExpirableFlag = formData.category === "expirable" ? "true" : "false";
  
      const response = await axios.post(process.env.REACT_APP_API_URL + "shorten", {
        originalUrl: formData.originalUrl,
        schemeType: formData.schemeType, 
        isExpirable: isExpirableFlag, // Use the dynamic flag
      });
  
      console.log("responseee", response);
      if (response.data.success) {
        setFormData({
          ...formData,
          fullShortUrl: response.data.result || "Error: No short URL returned",
        });
      } else {
        // Handle specific error responses from the server
        if (response.data.status === false) {
          navigate('/');
          setError(response.data.msg || "URL not found."); // Set the error message
          document.write("page not found");
          Swal.fire({
            icon: 'error',
            title: 'Not Found',
            text: response.data.msg || 'The URL you entered was not found.',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: response.data.msg || 'An error occurred!',
          });
        }
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Something went wrong. Please try again later.',
      });
    } finally {
      setLoading(false);
    }
  };
  
  const clearFields = () => {
    setFormData({
      originalUrl: "",
      fullShortUrl: "",
      category: "non-expirable",
      schemeType: null,
    });
    setError(""); // Clear the error message when fields are cleared
  };

  const copyToClipboard = (text) => {
    const input = document.createElement('input');
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
  };

  const handleCopy = () => {
    if (formData.fullShortUrl) {
      copyToClipboard(formData.fullShortUrl);
      toast.success('Short URL copied to clipboard!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        style: { fontSize: '12px' }
      });
    }
  };

  useEffect(() => {
    FetchAllScheme();
  }, []);

  return (
    <>
      <Navbar />
      <div className="container-fluid mt-5 d-flex justify-content-center">
        <div className="card p-4 shadow-sm rounded" style={{ width: "100%", maxWidth: "600px" }}>
          <h4 className="text-center text-white mb-4">URL SHORTENER</h4>
          {loading && (
            <div className="d-flex justify-content-center mb-3">
              <RotatingLines
                visible={true}
                height="50"
                width="50"
                color="grey"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
              />
            </div>
          )}
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <Form>
            <Form.Group className="mb-3" controlId="originalUrl">
              <Form.Label className="text-white">Enter URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your URL"
                name="originalUrl"
                value={formData.originalUrl}
                onChange={handleChange}
                style={{ borderRadius: "0.25rem" }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="category">
              <Form.Label className="text-white">URL Category</Form.Label>
              <Form.Control
                as="select"
                name="category"
                value={formData.category}
                onChange={handleChange}
                style={{ borderRadius: "0.25rem" }}
              >
                <option value="non-expirable">Non-Expirable</option>
                <option value="expirable">Expirable</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="schemeType">
              <Form.Label className="text-white">Scheme Category</Form.Label>
              <Select 
                className="select-style"
                options={optionItem} // Use the formatted options directly
                value={optionItem.find(option => option.label === formData.schemeType)} // Set the selected value
                onChange={handleCategoryChange}
                placeholder="Select scheme category..."
                isClearable
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="fullShortUrl">
              <Form.Label className="text-white">Short URL</Form.Label>
              <Form.Control
                type="text"
                name="fullShortUrl"
                placeholder="Shortened URL will appear here"
                value={formData.fullShortUrl}
                readOnly
                style={{ borderRadius: "0.25rem" }}
              />
            </Form.Group>
          </Form>
          <div className="d-flex gap-2 justify-content-center mt-3">
            <Button
              variant="danger"
              onClick={fillData}
              disabled={!formData.originalUrl || !formData.schemeType}
            >
              Generate Short URL
            </Button>
            <Button
              variant="primary"
              onClick={handleCopy}
              disabled={!formData.fullShortUrl}
            >
              Copy URL
            </Button>
            <Button
              variant="secondary"
              onClick={clearFields}
              disabled={!formData.originalUrl && !formData.fullShortUrl}
            >
              Clear Fields
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FrontPage;
